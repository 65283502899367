<template>
  <v-container>
    <v-row justify="center" class="mt-1">
      <v-col cols="12" md="8">
        <v-card elevation="10" class="pa-2">
          <v-img :src="img" height="20rem" contain></v-img>
          <v-card-title class="text-center text-h6 d-flex justify-center">
            Error 404
          </v-card-title>
          <v-card-text class="text-center text-h6">
            Page Wasn't Found
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'page404',

  data() {
    return {
      img: require('@/assets/images/error404.png'),
    }
  },

  metaInfo() {
    return {
      title: 'Not found',
      titleTemplate: '%s - Wael Dev',
    }
  },
}
</script>

<style></style>
